import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = () => (
  <Layout>
    <SEO title="Products" />
    <section className="section has-background-white" style={{
      marginTop: '90px'
    }}>
      <div className="container">
        <div className="columns">
          <div className="column">
            {/* Page title */}
            <h1 className="title is-spaced">Contact Form Submitted</h1>
            <h2 className="subtitle">Thank you for your message. It has been sent.</h2>
            {/* Back to homepage button */}
            <Link to="/">
              <button isColor="primary" className="button is-rounded" id="is-spaced">
                <span className="icon">
                <i className="fa fa-arrow-left fa-sm"></i>
                </span>
                <span>Go back to the homepage</span>
              </button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default SecondPage
